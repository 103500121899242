import { Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Broadcast } from 'src/app/models/Broadcast';
import { BroadcastContact } from 'src/app/models/BroadcastContact';
import { BroadcastsService } from 'src/app/shared/services/broadcasts.service';

import { IMPORTING, SCHEDULED } from '../../../models/BroadcastStatus';
import { PaginatedResult } from './../../../shared/model/paginated-result';
import { CopyService } from './../../../shared/services/copy.service';
import { providerStatus } from '../../../shared/utils';
import { NUMBER } from 'src/app/models/BroadcastType';
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/models/User';
import { ADMINISTRATOR } from 'src/app/shared/services/user.service';
import { ProviderAccountService } from 'src/app/shared/services/provider-account.service';


@Component({
  selector: 'app-view-broadcast',
  templateUrl: './view-broadcast.component.html',
  styleUrls: ['./view-broadcast.component.scss']
})
export class ViewBroadcastComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollContainer') private scrollContainer: ElementRef;

  page = 1;
  revisionPage = 1;
  perPage = 8;
  broadcastId: string;
  broadcast: Broadcast;
  contacts: PaginatedResult<BroadcastContact[]>;
  user: User;
  showTextarea:boolean = false;
  visibleContent: string = '';
  providerAccountsNames: object = {};
  phone: string = '';
  private boundOnScroll: any;
  
  
  subscriptions: Subscription[] = [];

  get TypeNumber() {
    return this.broadcast.broadcast_type_id === NUMBER
  }
  
  get isScheduled() {
    return this.broadcast.broadcast_status_id === SCHEDULED;
  }

  get isImporting() {
    return this.broadcast.broadcast_status_id === IMPORTING;
  }

  get isAdministrator(): boolean {
    return this.user.profile_id === ADMINISTRATOR;
  }

  isStringContent(content: any): boolean {
    return typeof content === 'string';
  }

  isArrayContent(content: any): boolean {
    return Array.isArray(content);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private copyService: CopyService,
    private broadcastsService: BroadcastsService,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private providerAccountService: ProviderAccountService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.broadcastId = params.id;
        this.findById();
        this.listContacts();
      })
    );

    this.user = this.authService.getUser(true);

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        const page = params.page || 1;
        this.page = page;
        this.listContacts();
      })
    );
    this.loadMoreContacts();
    this.subscriptions.push(
      this.providerAccountService.listAll()
        .subscribe(providerAccounts => {
          this.providerAccountsNames = providerAccounts.reduce((acc, item) => {
            acc[item.id] = item.name;
            return acc;
          }, {});
        })
    );
  }

  ngAfterViewInit(): void {
  
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    if (this.boundOnScroll && this.scrollContainer && this.scrollContainer.nativeElement) {
      this.scrollContainer.nativeElement.removeEventListener('scroll', this.boundOnScroll);
    }
  }

  toggleTextarea() {
    this.showTextarea = !this.showTextarea;
    this.changeDetectorRef.detectChanges();
    
    if (this.showTextarea) {
        setTimeout(() => {
            if (this.scrollContainer && this.scrollContainer.nativeElement) {
                this.boundOnScroll = this.onScroll.bind(this);
                this.scrollContainer.nativeElement.addEventListener('scroll', this.boundOnScroll);
            }
        }, 100);
    }
    
    if (!this.showTextarea && this.boundOnScroll && this.scrollContainer && this.scrollContainer.nativeElement) {
        this.scrollContainer.nativeElement.removeEventListener('scroll', this.boundOnScroll);
    }
  }


  onScroll(event: any) {
    const element = event.target;
    if (element.scrollHeight - Math.ceil(element.scrollTop) <= element.clientHeight + 10) {
        this.loadMoreContacts();
    }
  }

  loadMoreContacts() {
    this.subscriptions.push(
        this.broadcastsService
            .listContacts(this.broadcastId, this.revisionPage, 2000) //2000mil contatos por vez
            .subscribe(contacts => {
                const phoneNumbers = contacts.data.map(contact => contact.phone).filter(phone => phone);
                this.visibleContent += phoneNumbers.join('\n') + '\n';
                this.revisionPage++;
            })
    );
  }

  findById() {
    this.subscriptions.push(
      this.broadcastsService.findById(this.broadcastId).subscribe(
        broadcast => {
          this.broadcast = broadcast
        },
        () => this.router.navigate(['/broadcasts'])
      )
    );
  }

  filterByPhone(): void{
    this.page = 1
    this.listContacts(this.phone)
  }

  listContacts(phone?: string): void {
    this.subscriptions.push(
      this.broadcastsService
        .listContacts(this.broadcastId, this.page, this.perPage, phone)
        .subscribe(contacts => this.contacts = contacts)      
    );    
  }

  getStatus(contact: BroadcastContact): string {
    if (contact.sent_date && !contact.cancelled) {
      return 'Enviado';
    }

    if (contact.cancelled) {
      return 'Cancelado';
    }

    return 'Pendente';
  }

  pageChanged(page: any): void {
    this.router
      .navigate(['/broadcasts/view', this.broadcast.id], { queryParams: { page } });
  }

  copyUrl(): void {
    this.copyService.copy('url', 'URL copiada com sucesso!');
  }

  copyShortUrl(): void {
    this.copyService.copy('short_url', 'URL Curta copiada com sucesso!');
  }

  getProviderStatus(status: string = '') {
    let provider = providerStatus(status);

    return provider;
  }

}
