import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {Address} from 'src/app/models/Address';
import {ShortUrl} from 'src/app/models/ShortUrl';
import {environment} from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AppService {

  words: string[] = [];

  constructor(
    private http: HttpClient
  ) { }

  shorten(url: string): Observable<ShortUrl> {
    return this.http.post<ShortUrl>(`${environment.apiUrl}/shorten`, { url })
      .pipe(take(1));
  }

  blackList(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.baseUrl}/blacklist`)
      .pipe(tap(blackList => this.words = blackList))
      .pipe(take(1));
  }

  savaBlackList(words: string[]): Observable<string[]> {
    return this.http.put<string[]>(`${environment.baseUrl}/api/blacklist`, { words })
      .pipe(tap(blackList => this.words = blackList))
      .pipe(take(1));
  }

  searchAddress(postcode: string): Observable<Address> {
    return this.http.get<Address>(`${environment.apiUrl}/cep/${postcode}`)
      .pipe(take(1));
  }
}
