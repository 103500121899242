import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AccordionCardFlatComponent } from './accordion-card-flat/accordion-card-flat.component';
import { AccordionCardComponent } from './accordion-card/accordion-card.component';
import { AccordionPanelComponent } from './accordion-panel/accordion-panel.component';
import { AddressComponent } from './components/address/address.component';
import { Call4UPopupComponent } from './components/call4u/popup.component';
import { UpdateBillingInfosPopupComponent } from './components/billing-infos/popup.component';
import { ACPopupComponent } from './components/active-campaign/popup.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { FileComponent } from './components/file/file.component';
import { HiddenFieldComponent } from './components/hidden-field/hidden-field.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PlanMessageComponent } from './components/plan-message/plan-message.component';
import { ToastComponent } from './components/toast/toast.component';
import { VerificationMessageComponent } from './components/verification-message/verification-message.component';
import { VerificationMessageWhatsappComponent } from './components/verification-message-whatsapp/verification-message-whatsapp.component';
import { CepDirective } from './directives/cep.directive';
import { CpfDirective } from './directives/cpf.directive';
import { CreditCardDirective } from './directives/credit-card.directive';
import { PhoneDirective } from './directives/phone.directive';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { UserInterceptor } from './interceptors/user.interceptor';
import { PaginationComponent } from './pagination/pagination.component';
import { BooleanStringPipe } from './pipes/boolean-string.pipe';
import { BroadcastStatusPipe } from './pipes/BroadcastStatus.pipe';
import { IntervalTypePipe } from './pipes/interval-type.pipe';
import { PaymentStatusPipe } from './pipes/payment-status.pipe';
import { PeriodPipe } from './pipes/period.pipe';
import { PrettyJsonPipe } from './pipes/pretty-json.pipe';
import { ProfilePipe } from './pipes/profile.pipe';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { SwitcherComponent } from './switcher/switcher.component';
import { AnalyticsCardComponent } from './analytics/card/card.component';

@NgModule({
  declarations: [
    PaginationComponent,
    LoadingComponent,
    ToastComponent,
    ConfirmationModalComponent,
    HiddenFieldComponent,
    SwitcherComponent,
    AccordionCardComponent,
    AccordionPanelComponent,
    AccordionCardFlatComponent,
    RadioButtonComponent,
    PhoneDirective,
    CpfDirective,
    CreditCardDirective,
    CepDirective,
    PlanMessageComponent,
    VerificationMessageComponent,
    VerificationMessageWhatsappComponent,
    FileComponent,
    PrettyJsonPipe,
    BooleanStringPipe,
    IntervalTypePipe,
    PaymentStatusPipe,
    ProfilePipe,
    AddressComponent,
    PeriodPipe,
    BroadcastStatusPipe,
    Call4UPopupComponent,
    ACPopupComponent,
    UpdateBillingInfosPopupComponent,
    AnalyticsCardComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, NgbModule],
  exports: [
    AccordionCardComponent,
    AccordionPanelComponent,
    AccordionCardFlatComponent,
    AddressComponent,
    ConfirmationModalComponent,
    HiddenFieldComponent,
    LoadingComponent,
    PaginationComponent,
    RadioButtonComponent,
    SwitcherComponent,
    ToastComponent,
    PhoneDirective,
    CpfDirective,
    CreditCardDirective,
    CepDirective,
    PlanMessageComponent,
    FileComponent,
    PrettyJsonPipe,
    BooleanStringPipe,
    IntervalTypePipe,
    PaymentStatusPipe,
    ProfilePipe,
    PeriodPipe,
    BroadcastStatusPipe,
    VerificationMessageComponent,
    VerificationMessageWhatsappComponent,
    Call4UPopupComponent,
    ACPopupComponent,
    UpdateBillingInfosPopupComponent,
    AnalyticsCardComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    ProfilePipe
  ]
})
export class SharedModule { }
