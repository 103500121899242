import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-analytics-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class AnalyticsCardComponent {
  @Input() title: string;
  @Input() highlightText: string;
  @Input() tooltipText: string;
  @Input() autoHeight = 'true';
  @Input() class = '';
}
