<app-page-title icon="fa fa-adjust" title="Blacklist" data-testid="blacklist-section">
  <button class="btn btn-success" (click)="open()">Adicionar</button>
</app-page-title>

<div class="mb-2 card">
  <div class="row">
    <div class="col">
      <input type="text" class="form-control" placeholder="Digite a palavra para pesquisar" [(ngModel)]="filter" />
    </div>
  </div>

  <div *ngFor="let word of words; let i = index" class="word">
    <button type="button" class="btn btn-danger" (click)="delete(word)">
      <i class="fa fa-trash"></i>
    </button>
    {{ word }}
  </div>
</div>

<ng-template #modalAdd let-modalAdd>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Adicionar palavra</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modalAdd.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <h3>Palavra:</h3>
      <input type="text" class="form-control" [(ngModel)]="word" />
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="save()">Salvar</button>
      <button type="button" class="btn btn-outline-primary" (click)="modalAdd.dismiss()">Fechar</button>
    </div>
  </div>
</ng-template>
