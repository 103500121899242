<app-page-title *ngIf="user.api_enabled" icon="fa-cloud" title="API"></app-page-title>
<div *ngIf="user.api_enabled" class="card elevated" style="padding: 50px;">
    <div class="row">
        <div class="card gradient-row danger">
            ATENÇÃO: Este conteúdo é de uso exclusivo e confidencial. Pedimos que não seja compartilhado com terceiros.
        </div>
        <button class="btn btn-primary btn-generate" (click)="generateToken()">
            Gerar Chave de API
        </button>
        <input 
            id="token"
            class="form-control token-input" 
            type="password" 
            readonly 
            [value]="token ?? null"
            (click)="token ? copyToken() : undefined"
        />
        <button class="btn btn-primary btn-copy" [disabled]="token == null" (click)="copyToken()" ngbTooltip="Copiar">
            <i class="fa fa-copy"></i>
        </button>
    </div>
    <div class="observations">
        <span *ngIf="generated_at" style="margin-top:5px">Gerado em: {{ generated_at | date: "dd/MM/yyyy H:mm:ss" }}</span>
        <a href="https://docs.smsfunnel.com.br/api/parceiros" target="_blank">Documentação</a>
    </div>

</div>
