import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Sequence } from 'src/app/models/Sequence';

import { ConfirmationService } from '../../../shared/components/confirmation-modal/confirmation.service';
import { Campaign } from './../../../models/Campaign';
import { LeadList } from './../../../models/LeadList';
import { ToastService } from './../../../shared/components/toast/toast.service';
import { CampaignService } from './../../../shared/services/campaigns.service';
import { LoadingService } from './../../../shared/components/loading/loading.service';
import { ListsService } from './../../../shared/services/lists.service';
import { ActiveCampaignService } from 'src/app/shared/services/active-campaign.service';

@Component({
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, OnDestroy {

  title = 'Nova automação';

  active = true;
  lists: LeadList[];
  campaignId: string;
  campaign: Campaign;
  sequences: Sequence[];
  formCampaign: UntypedFormGroup;
  showAlert: boolean

  ac: boolean = false;
  acURLInvalid: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuild: UntypedFormBuilder,
    private campaignService: CampaignService,
    private listsService: ListsService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private ACService: ActiveCampaignService,
    private loadingService: LoadingService,
  ) { }

  ngOnInit(): void {
    this.formCampaign = this.formBuild.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      lead_list_id: ['', Validators.required],
      send_to_all: [false, Validators.required],
      acURL: ['', Validators.pattern('^$|^https:\\/\\/(?:[a-zA-Z0-9-]+\\.activehosted\\.com|[a-zA-Z0-9-]+\\.api-us\\d\\.com)$')],
      acKey: [''],
      active: [true]
    });

    this.subscriptions.push(
      this.formCampaign.get('lead_list_id').valueChanges.subscribe((leadListId) => {
        const list = this.lists.find(l => l.id === leadListId);
        this.showAlert = list.integration && !list.integration.active;
      })
    );

    this.loadingService.stop();
    this.subscriptions.push(
      this.listsService.listAll().subscribe(lists => {
        this.lists = lists;
        if (lists.length === 0) {
          this.toastService.error('Você deve cadastrar uma lista antes de criar uma automação!');
        }
      })
    );

    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        if (params.id) {
          this.campaignId = params.id;
          this.title = 'Alterar automação';
          this.loadCampaign();
        }
      })
    );
  }

  loadCampaign() {
    this.subscriptions.push(
      this.campaignService.findById(this.campaignId).subscribe(campaign => {
        this.formCampaign.patchValue(campaign);
        this.active = campaign.active;
        this.sequences = campaign.sequences;
        this.campaign = campaign;
        this.observeListChanges();
        if (campaign.active_campaign) {
          this.ac = campaign.active_campaign.url ? true : false;
          this.formCampaign.patchValue({ acURL: campaign.active_campaign.url.replace('/api/3', ''), acKey: '--------------<YOUR_SECURE_KEY>-------------' })
        }
      })
    );
  }

  observeListChanges() {
    this.subscriptions.push(
      this.formCampaign.get('lead_list_id').valueChanges.subscribe(id => {
        const campaign = this.campaign;
        campaign.lead_list = this.lists.find(l => l.id === id);
        this.campaign = campaign;

      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleActive(): void {
    this.active = !this.active;
    this.formCampaign.patchValue({ active: this.active });
    if (this.campaignId) {
      this.save();
    }
  }

  toggleSendType(): void {
    if (this.campaignId) {
      this.save();
    }
  }

  saveCampaign(campaign): void {
    const successMessage = campaign.id
    ? 'Automação alterada com sucesso!'
    : 'Automação cadastrada com sucesso!';

    const errorMessage = campaign.id
      ? 'Erro ao alterar automação! Tente novamente mais tarde'
      : 'Erro ao cadastrar automação! Tente novamente mais tarde';

    this.subscriptions.push(
      this.campaignService.save(campaign).subscribe(
        res => {
          this.toastService.success(successMessage);
          if (!campaign.id) {
            this.router.navigate(['/campaigns/edit', res.id]);
          }

          this.campaign = res;
        },
        err => this.toastService.error(errorMessage)
      )
    );
  }

  save(): void {
    const campaign = this.formCampaign.getRawValue() as Campaign;

    const apiURL = this.formCampaign.get('acURL').value;
    const apiKey = this.formCampaign.get('acKey').value;

    if (apiURL && apiKey && (this.formCampaign.get('acKey').dirty || this.formCampaign.get('acURL').dirty)) {
      this.ACService.activate(apiKey, apiURL).subscribe((activate) => {
        if (activate && activate.status === true) {
          this.toastService.success('Active Campaign validado com sucesso!');
          this.saveCampaign(campaign);
          this.ngOnInit();
          setTimeout(() => window.location.reload(), 3000);
        }else{
          this.formCampaign.patchValue({acKey: null, acURL: null});
          this.toastService.error('Dados do Active Campaign inválidos. Por favor, verifique se estão corretos e tente novamente.');
          return
        };
      })
    } else {
      if (!apiKey && !apiURL) {
        this.ac = false;
      }
      this.saveCampaign(campaign);
    }
  }

  resend(): void {
    const list = this.lists.find(l => l.id === this.formCampaign.get('lead_list_id')?.value);
    const message = `Esta automação será enviada novamente para todos os contatos da lista <b>${list.name}</b>. `
      + 'Deseja realmente reiniciar esta automação?';

    this.confirmationService.show(message, () => {
      this.subscriptions.push(
        this.campaignService.resend(this.campaignId).subscribe({
          next: () => this.toastService.success('Automação reenviada com sucesso!'),
          error: err => this.toastService.error(err.error?.message || 'Erro ao reenviar automação. Tente novamente mais tarde!')
        })
      );
    });
  }

  includeAC(): void {
    if (this.ac) {
      this.formCampaign.patchValue({ acURL: null, acKey: null })
      this.ac = false;
    }else {
      this.ac = true;
    }
  }
}
