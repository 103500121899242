export const PENDING = '26e49df2-6977-4d38-8834-4af0012c97d4';
export const CANCELLED = 'd6cd1581-ac20-4420-b93b-64a37cff060c';
export const IMPORTING = '2b33f0b5-432a-47ae-aef4-7ab1e4234247';
export const SCHEDULED = '8278cb5e-5087-41b7-a5f1-c6665430e633';
export const SENDING = 'efe2830d-5823-437a-b5a5-9b56c3fe29c6';
export const SENT = '43f7734e-6bf2-4aeb-a05e-0d70d631cc71';
export const SENT_WITH_ERRORS = '60308b32-b5cc-471e-8a4f-4cff2bbf19e0';

export interface BroadcastStatus {
  id: string;
  status: string;
}
