import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {map, take} from 'rxjs/operators';

export interface Video {
  title: string;
  id: string;
  type?: string;
  url?: SafeUrl;
}

@Injectable({ providedIn: 'root' })
export class HelpService {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }
  tutorial(): Observable<Video[]> {
    return this.http.get<Video[]>(`${environment.apiUrl}/tutorial`)
      .pipe(map(videos => videos.map(v => this.addSafeUrl(v))))
      .pipe(take(1));
  }

  create(url: string, video: Video): Video {
    if (url.includes('youtube') || url.includes('youtu.be')) {
      video.type = 'youtube';
    } else if (url.includes('vimeo')) {
      video.type = 'vimeo';
    } else {
      return video;
    }

    let items = [];
    if (video.type === 'youtube') {
      if (url.includes('v=')) {
        video.id = url.split('v=')[1];
      } else {
        console.log(url);
        if (url.includes('?')) {
          items = url.split('?')[0].split('/');
        } else {
          items = url.split('/');
        }
        video.id = items[items.length - 1];
      }

    } else if (video.type === 'vimeo') {
      if (url.includes('?')) {
        items = url.split('?')[0].split('/');
      } else {
        items = url.split('/');
      }

      if (items.length > 0) {
        video.id = items[items.length - 1];
      }
    }

    video = this.addSafeUrl(video);

    return video;
  }

  addSafeUrl(video: Video): Video {
    let url = '';
    if (video.type === 'vimeo') {
      url = 'https://player.vimeo.com/video/{:id}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
    } else if (video.type === 'youtube') {
      url = 'https://www.youtube.com/embed/{:id}';
    }
    video.url = this.sanitizer.bypassSecurityTrustResourceUrl(url.replace('{:id}', video.id));
    return video;
  }

  save(videos: Video[]): Observable<any> {
    return this.http.put(`${environment.apiUrl}/tutorial`, { videos }).pipe(take(1));
  }
}
