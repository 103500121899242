import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { Video, HelpService } from './help.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  videos: Video[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private helpService: HelpService
  ) { }

  ngOnInit(): void {
    this.helpService
      .tutorial()
      .subscribe(videos => this.videos = videos);
  }

}
