import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect-signup-component',
  templateUrl: './redirect-signup-component.component.html',
  styleUrls: ['./redirect-signup-component.component.scss']
})
export class RedirectSignupComponentComponent implements OnInit {

  constructor(
    private route: ActivatedRoute
  ){}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const promocode = params.code ? `?promocode=${params.code}` : '';
      
      // Captura todos os parâmetros de consulta
      this.route.queryParams.subscribe(queryParams => {
        const allEntries = Object.entries(queryParams)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join('&'); // Junta todos os parâmetros com '&'

        const queryString = allEntries ? `&${allEntries}` : ''; // Adiciona '&' se houver parâmetros

        window.location.href = `https://cadastro.smsfunnel.com.br/${promocode}${queryString}`; // Redireciona com todos os parâmetros
      });
    });
  }
}
