import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from './../../shared/services/auth.service';
import { ToastService } from './../../shared/components/toast/toast.service';
import { User } from 'src/app/models/User';
import { Router } from '@angular/router';
import { ResendTokenResponse } from 'src/app/models/ResendTokenResponse';

@Component({
  selector: 'app-account-confirmation',
  templateUrl: './account-confirmation.component.html',
  styleUrls: ['./account-confirmation.component.scss']
})

export class AccountConfirmationComponent implements OnInit {
  user: User;
  title = 'Confirmação de Conta';
  accountConfirmationForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }
  
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.accountConfirmationForm = this.formBuilder.group({
      sms_code: ['', Validators.required],
    });
  
    this.user = this.authService.getUser();
  
    this.authService.userChanged.subscribe(() => {
      if (this.authService.isSimulating) {
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/change-password']);
      }
    });

    this.resendToken(true);
  }

  update(): void {
    const tokenSms = this.accountConfirmationForm.get('sms_code').value;

    if (this.accountConfirmationForm.invalid) {
      return;
    }
    
    if (!tokenSms) {
      this.toastService.error('Por favor, forneça o token SMS.');
      return;
    }
  
    this.authService.validateVerifyToken(this.user.id, tokenSms).subscribe(
      response => {
        if (response.success) {
          this.toastService.success('Token validado com sucesso!');
          this.router.navigate(['/']);
        } else {
          this.toastService.error(response.message);
        }
      },
      err => {
        // Usando diretamente a mensagem de erro se disponível
        const errorMessage = err.error?.message || 'Erro ao autenticar o Token. Tente novamente.';
        this.toastService.error(errorMessage);
        console.log(err);
      }
    );
  }
  
  resendToken(isAutomatic = false): void {
    this.authService.resendSmsToken(isAutomatic).subscribe(
      (response: ResendTokenResponse) => {
        if (response.success) {
          this.toastService.success('Token reenviado com sucesso!');
          return;
        }

        this.toastService.error(response.message);        
      },
      err => {
        if (err.status === 429 && !isAutomatic) {
          this.toastService.error(err.error.message);
          return;
        }
        if (!isAutomatic) {
          this.toastService.error('Erro ao tentar reenviar o token. Tente novamente mais tarde.');
        }
      }
    );
  }
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const numericOnly = inputValue.replace(/[^0-9]/g, '');

    this.accountConfirmationForm.patchValue({sms_code: numericOnly})
  }
  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}