<div class="card elevated sms-token-form mt-5">
  <div class="form-group mb-2">
    <p class="text-center text-uppercase token-title">Token de autenticação</p>
    <p class="text-center">
      Insira abaixo o token enviado para o número: 
      <span class="fw-bold">{{ user.phone }}</span>
    </p>
  </div>
  <div class="form-group">
    <form [formGroup]="accountConfirmationForm" (submit)="update()">
      <div class="mb-3">
      <input
        type="text"
        class="form-control w-75 mx-auto text-center"
        formControlName="sms_code"
        aria-describedby="inputGroup-sizing-lg"
        id="sms_code"
        name="sms_code"
        maxlength="6"
        (input)="onInputChange($event)"
      />
      <span *ngIf="accountConfirmationForm.get('sms_code')?.invalid">
        <small
          class="text text-danger mx-auto d-flex justify-content-center"
          *ngIf="accountConfirmationForm.get('sms_code').errors?.required"
        >
          O token é obrigatório.
        </small>
      </span>
      </div>

      <div class="d-flex justify-content-center">
        <button class="btn btn-primary w-75 mx-auto ">
          Confirmar
        </button>
      </div>
    </form>

  <div class="text-center mt-5">
    <button
      class="btn btn-secondary me-2"
      id="resendTokenButton"
      (click)="resendToken()"
      >
      Reenviar o Token via SMS
    </button>
    <button
      class="btn btn-secondary"
      id="logout"
      (click)="logout()"
      >
      Sair
    </button>
  </div>
</div>
