import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from './../../../environments/environment';

import { User } from 'src/app/models/User';
import { Token } from 'src/app/models/Token';
import { ResendTokenResponse } from 'src/app/models/ResendTokenResponse';

const USER_KEY = 'SMSFUNNEL::AUTH';
const GLOBAL_ACCOUNT = 'SMSFUNNEL::GLOBAL_ACCOUNT';
const SIMULATED_USER_KEY = 'SMSFUNNEL::SIMULATED_USER';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  get isSimulating() {
    const user = this.getLoggedInUser();
    const simulatedUser = this.getSimulatedUser();
    return simulatedUser && simulatedUser.id != user.id;
  }

  get isGlobalAccount() {
    const global = localStorage.getItem(GLOBAL_ACCOUNT);
    return global ? true : false;
  }

  userChanged: EventEmitter<User> = new EventEmitter();

  constructor(
    private http: HttpClient
  ) { }

  login(user: User): Observable<Token> {
    return this.http.post<Token>(`${environment.apiUrl}/login`, user)
      .pipe(tap(authUser => {
        if (!authUser.user.beta) {
          this.saveData(authUser);
        }
        return authUser;
      }));
  }

  loginBeta(user: User): Observable<Token> {
    return this.http.post<Token>(`${environment.apiBetaUrl}/login`, user)
      .pipe(tap(authUser => {
        return authUser;
      }));
  }

  logout(): void {
    this.http
      .post(`${environment.apiUrl}/logout`, {})
      .subscribe(() => { });

    this.removeData();
  }

  checkTokenExpired(): boolean {

    if (false === this.isLogged()) {
      return true;
    }

    const data = this.getSavedData();

    if (Date.now() > data.expiration * 1000) {
      this.logout();
      return true;
    }

    return false;
  }

  removeData(): void {
    localStorage.clear();
  }

  saveData(authData: Token): void {
    if (authData.user.globalActivated === undefined) {
      authData.user.globalActivated = false;
    }
    localStorage.setItem(USER_KEY, JSON.stringify(authData));
  }

  validateVerifyToken(userId: string, token: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account-confirmation`, { userId, token })
    .pipe(tap(() => {
      const userData = this.getSavedData();
      userData.user.sms_token_validated = true;
      this.saveData(userData);
    }));
  }

  resendSmsToken(isAutomatic): Observable<ResendTokenResponse> {
    return this.http.post<ResendTokenResponse>(`${environment.apiUrl}/resend-token${isAutomatic ? '?automatic=true' : ''}`, {})
  }

  changePassword(password: string, newPassword: string): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/change-password`, { password, new_password: newPassword })
      .pipe(tap(() => {
        const userData = this.getSavedData();
        userData.user.temporary = false;
        this.saveData(userData);
      }));
  }

  getSavedData(): any {
    const savedData = localStorage.getItem(USER_KEY);
    if (savedData) {
      return JSON.parse(savedData);
    }
  }

  getUser(force_original: boolean = false): User {
    const simulatedUser = this.getSimulatedUser();
    if (simulatedUser && !force_original) {
      return simulatedUser;
    }

    const authData = this.getSavedData();
    if (authData) {
      return authData.user;
    }
  }

  getUserById(id: string): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  getLoggedInUser(): User {
    let authData = null;
    const savedData = localStorage.getItem(USER_KEY);
    if (savedData) {
      authData = JSON.parse(savedData);
    }
    if (authData) {
      return authData.user;
    }
  }

  setUser(user: User): void {
    const authData = this.getSavedData();
    if (authData) {
      authData.user = user;
      this.saveData(authData);
      this.userChanged.emit(user);
    }
  }

  getToken(): string {
    const authData = this.getSavedData();
    if (authData) {
      return authData.access_token;
    }
  }

  isLogged(): boolean {
    const user = this.getUser();
    return user ? true : false;
  }

  setSimulatedUser(user?: User) {
    if (user) {
      user.simulated = true;      
      localStorage.setItem(SIMULATED_USER_KEY, JSON.stringify(user));
    } else {
      localStorage.removeItem(SIMULATED_USER_KEY);
    }
    user = this.getUser();    
    this.userChanged.emit(user);
  }

  getSimulatedUser(): User {
    const localUser = localStorage.getItem(SIMULATED_USER_KEY);
    if (localUser) {
      return JSON.parse(localUser) as User;
    }

    return null;
  }

  updateUser(user: User) {
    if (this.isSimulating) {
      this.setSimulatedUser(user);
    } else {
      this.setUser(user);
    }
  }

  toggleGlobalAccount() {
    const user = this.getUser();
    if (this.isGlobalAccount) {
      user.globalActivated = false;
      localStorage.removeItem(GLOBAL_ACCOUNT);
    } else {
      user.globalActivated = true;
      localStorage.setItem(GLOBAL_ACCOUNT, 'true');
    }
    this.updateUser(user);
  }
}
