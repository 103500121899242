<ng-template #modalSequenceAC let-modal>
    <div class="modal-header">
      <h1>Ativação do Active Campaign</h1>
    </div>
  
    <div class="modal-body" style="padding: 3rem;">
        <h5>Por favor, ative este serviço nas configurações da sua campanha. Feito isto, basta recarregar a página e tudo estará pronto!</h5>
    </div>
  
    <div class="modal-footer">
      <button
      type="button"
      class="btn btn-primary"
      (click)="reload()"
        >
      Já ativei - Recarregar página
      </button>
      <button
      type="button"
      class="btn btn-secondary"
      (click)="modalRef.dismiss()"
        >
      Fechar
      </button>
    </div>
  </ng-template>
  