<app-page-title icon="fa-bullhorn" title="Validação de SMS Massivo"></app-page-title>
<form [formGroup]="broadcastForm" (submit)="pageChanged(page)">
  <div class="row col-md-12">
    <div class="col-md-12 col-sm-12" style="width: fit-content">
      <mat-form-field>
        <mat-label>Data inicial</mat-label>
        <input matInput readonly [matDatepicker]="pickerStart" name="startedMonth" formControlName="startDate" />
        <mat-datepicker-toggle matIconSuffix [for]="pickerStart" />
        <mat-datepicker #pickerStart></mat-datepicker>
        <small class="text text-danger"
          *ngIf="invalidTime">
          Período inválido.
        </small>
      </mat-form-field>
    </div>
    <div class="col-md-12 col-sm-12" style="width: fit-content">
      <mat-form-field>
        <mat-label>Data final</mat-label>
        <input matInput readonly [matDatepicker]="pickerEnd" name="endedMonth" formControlName="endDate" />
        <mat-datepicker-toggle matIconSuffix [for]="pickerEnd" />
        <mat-datepicker #pickerEnd></mat-datepicker>
        <small class="text text-danger"
          *ngIf="invalidTime">
          Período inválido.
        </small>
      </mat-form-field>
    </div>
  </div>
  <div class="row col-md-12 col-sm-12 flex-wrap">
    <div class="row mb-2">
      <div class="col-md-6 col-xs-12">
          <label>Nome do envio massivo</label>
          <input
            type="text"
            name="text"
            formControlName="text"
            class="form-control"
            placeholder="Nome do envio massivo"
          />
      </div>
      <div class="col-md-6 col-xs-12">
          <label>Usuário</label>
          <input
            name="author"
            placeholder="Nome ou e-mail do usuário"
            class="form-control"
            formControlName="author"
          />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
          <app-switcher
              display="block"
              label="Somente pendentes de análise"
              [checked]="pendingOnly"
              (change)="togglePending()"
          ></app-switcher>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <button class="btn btn-primary" type="submit" [disabled]="invalidTime">Pesquisar</button>
        <button class="btn btn-secondary ms-2" (click)="broadcastForm.reset(); pageChanged(1)">Limpar</button>
      </div>
    </div>
    <div class="row mb-5">
      <hr class="mt-4" />
      <div class="col-xs-12 mt-2 mb-2 d-flex flex-row">
        <div style="width: 90%">
          <label>Ordenar por</label>
          <select
            name="orderBy"
            id="orderBy"
            class="form-control"
            formControlName="orderBy"
            (change)="listPaginated()"
          >
            <option value="created_at">Data</option>
            <option value="broadcast_status_id">Status</option>
            <option value="leads_count">Leads</option>
          </select>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-2"
          style="width: fit-content; margin: auto"
        >
          <label>Reverso</label>
          <app-switcher
            [checked]="invertedOrder"
            (change)="invertOrder()" 
          />
        </div>
      </div>
    </div>
  </div>
</form>

<section>
  <app-analytics-card *ngFor="let broadcast of result?.data" class="mb-2">
    <div class="row">
      <h3>{{ getTitle(broadcast) }}</h3>
    </div>
    <div class="row">
      <div class="col col-xs-12">
        <label>Data do envio:</label>
        <p>{{ getDate(broadcast) | date:'dd/MM/yyyy' }}</p>
      </div>
      <div class="col col-xs-12">
        <label>Mensagem:</label>
        <p class="word-wrap" ngbTooltip="URL Original: {{ broadcast.url }}">{{ getMessage(broadcast) }}</p>

        <div *ngIf="broadcast.short_url" class="url">
          <label>URL Original:</label>
          <a [href]="broadcast.url" target="_blank" class="word-wrap">{{ broadcast.url }}</a>
        </div>
      </div>
      <div class="col col-xs-12">
        <label>Usuário:</label>
        <p [innerHTML]="getUser(broadcast)"></p>
      </div>
      <div class="col col-xs-12">
        <label>Status:</label>
        <p>{{ broadcast.broadcast_status_id | broadcastStatus }}</p>
      </div>
      <div class="col col-xs-12" *ngIf="!pendingOnly">
        <label>Responsável aprovação:</label>
        <p>{{ broadcast.reviewer ? broadcast.reviewer.name : '-' }}</p>
      </div>
      <div class="col col-xs-12">
        <div class="row">
          <button
            type="button"
            class="btn col btn-primary mb-1 me-1"
            (click)="approve(broadcast)"
            [disabled]="isPendingReview(broadcast) == false"
          >
            Aprovar
          </button>
          <button
            type="button"
            class="btn col btn-secondary"
            (click)="cancel(broadcast)"
            [disabled]="isPendingReview(broadcast) == false"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </app-analytics-card>
  <app-pagination *ngIf="result" [currentPage]="result.current_page" [itemsPerPage]="result.per_page"
    [totalItems]="result.total" (pageChanged)="pageChanged($event)"></app-pagination>
</section>

<ng-template #notFound>
  <tr *ngIf="!loading && user.validated">
    <td colspan="7" style="padding: 10px">Nenhum envio em massa para validação!</td>
  </tr>
</ng-template>

<ng-template #modalValidation let-modalValidation>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Cancelamento</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modalValidation.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-2">
          <label>Data do envio:</label>
          <p class="form-control">{{ getDate(broadcast) | date:'dd/MM/yyyy' }}</p>
        </div>
        <div class="col">
          <label>Usuário:</label>
          <p class="form-control" [innerHTML]="getUser(broadcast)"></p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Mensagem:</label>
          <p class="form-control">{{ getMessage(broadcast) }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label>Justificativa:</label>
          <input type="text" class="form-control" [(ngModel)]="reason">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="disapprove()">Cancelar</button>
      <button type="button" class="btn btn-outline-primary" (click)="modalValidation.dismiss()">Fechar</button>
    </div>
  </div>
</ng-template>
