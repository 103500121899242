<app-page-title icon="fa-money" title="Adicionar créditos"></app-page-title>

<app-plan-message></app-plan-message>

<section class="recharges">
  <div class="card elevated">
    <div class="card-title">
      <div class="step mt-4">
        <p
          [class.selected]="mt.id === tab"
          *ngFor="let mt of tabs; let i = index"
        >
          <span
            class="badge badge-pill"
            [class.badge-primary]="mt.id === tab"
            [class.badge-info]="mt.id !== tab"
          >
            <i class="fa fa-check" *ngIf="mt.id === tab"></i>
            <span *ngIf="mt.id !== tab">{{ i + 1 }}</span>
          </span>
          {{ mt.text }}
        </p>
        <p class="separator"></p>
      </div>
    </div>

    <form (submit)="buy($event)" [formGroup]="rechargeForm">
      <div class="card-body" *ngIf="tab === 'credits'">
        <div class="form-group row mb-2">
          <label class="col-sm-12 col-md-5" for="credits">Tipo de Saldo</label>
          <!-- Select Sms ou Whatsapp -->
          <select
            [(ngModel)]="target"
            (change)="targetChanged($event)"
            name="target"
            id="target"
            formControlName="target"
            class="col-sm-12 col-md-7 form-control"
          >
            <option selected value="sms">SMS</option>
            <!-- <option value="whatsapp">Whatsapp</option> -->
          </select>
        </div>
        <div class="form-group row mb-2">
          <label class="col-sm-12 col-md-5" for="credits">Quantidade</label>
          <input
            type="number"
            name="credits"
            id="credits"
            min="100"
            #inputCredits
            formControlName="credits"
            class="col-sm-12 col-md-7 text-right form-control"
          />
          <span class="text text-danger" *ngIf="credits < 100"
            >O mínimo é 100 créditos</span
          >
        </div>
        <div class="form-group row mb-2">
          <label class="col-sm-12 col-md-5" for="price">Valor Unitário</label>
          <span class="col-sm-12 col-md-7 text-right form-control disabled">{{
            price | numberFormat : "R$ " : true : 3
          }}</span>
        </div>
        <div class="form-group row">
          <label class="col-sm-12 col-md-5" for="price">Total</label>
          <div class="col-sm-12 col-md-7 text-right form-control disabled">
            <span *ngIf="credits">{{
              total | numberFormat : "R$ " : true
            }}</span>
          </div>
        </div>

        <div class="row">
          <table class="table" *ngIf="pagePrices.length > 1">
            <thead>
              <tr>
                <th>Quantidade</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let p of pagePrices">
                <td>
                  {{
                    (p.startQuantity | numberFormat) +
                      " a " +
                      (p.endQuantity | numberFormat)
                  }}
                </td>
                <td>{{ p.price | numberFormat : "R$ " }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card-body" *ngIf="tab === 'address'">
        <div class="row mb-3">
          <label>O endereço será solicitado apenas a primeira vez!</label>
        </div>

        <app-address
          [addressForm]="rechargeForm"
          (showAdressChange)="showAddress = $event"
          [focused]="true"
        ></app-address>
      </div>

      <div class="card-body" *ngIf="tab === 'payment'">
        <div class="row mb-3">
          <div class="col-sm-6" *ngIf="user.validated && user.credit_payment_enabled">
            <button
              type="button"
              class="btn mb-3 btn-block"
              (click)="togglePaymentType('credit_card')"
              [class.btn-primary]="paymentType === 'credit_card'"
              [class.btn-secondary]="paymentType !== 'credit_card'"
            >
              <i class="fa fa-credit-card"></i>
              Cartão de Crédito
            </button>
          </div>
          <div class="col-sm-6">
            <button
              type="button"
              class="btn btn-block"
              (click)="togglePaymentType('pix')"
              [class.btn-primary]="paymentType === 'pix'"
              [class.btn-secondary]="paymentType !== 'pix'"
            >
              <img src="/assets/pix.gif" width="15" alt="Pix image" />
              PIX
            </button>
          </div>
        </div>

        <div class="row mb-3">
          <app-switcher
              display="block"
              style="margin-bottom: 20px;user-select: none;"
              label="Recarregar com CNPJ"
              [(checked)]="isCnpj"
          ></app-switcher>
          <div class="col-md-6">
            <input
              #document
              type="text"
              id="document"
              name="document"
              [placeholder]="isCnpj ? 'CNPJ' : 'CPF'"
              class="form-control"
              formControlName="document"
              maxlength="18"
              (input)="formatCpfCnpj($event)"
              (valueChanged)="changeDocument($event)"
            />
            <span
              class="text text-danger"
              *ngIf="
                rechargeForm.get('document').errors?.minlength
              "
            >
              {{ isCnpj ? 'CNPJ' : 'CPF' }} inválido
            </span>
          </div>
          <div class="col-md-6">
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Telefone"
              class="form-control"
              formControlName="phone"
              appPhone
              (valueChanged)="changePhone($event)"
            />
            <span
              class="text text-danger"
              *ngIf="
                rechargeForm.get('phone').errors?.minlength ||
                (rechargeForm.get('phone').touched &&
                  rechargeForm.get('phone').errors?.required)
              "
            >
              Telefone é obrigatório
            </span>
          </div>
        </div>

        <div class="row mb-3" *ngIf="paymentType === 'pix'; else cardPayment">
          <div class="col-12">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Nome"
              class="form-control"
              formControlName="name"
            />
          </div>
        </div>

        <ng-template #cardPayment>
          <div class="row mb-3">
            <div class="col-12">
              <input
                type="text"
                id="card"
                name="card"
                class="form-control"
                formControlName="card_number"
                placeholder="Número do cartão"
                appCreditCard
                (valueChanged)="changeCardNumber($event)"
              />
              <span
                class="text text-danger"
                *ngIf="
                  rechargeForm.get('card_number').errors?.minlength ||
                  (rechargeForm.get('card_number').touched &&
                    rechargeForm.get('card_number').errors?.required)
                "
              >
                Cartão é obrigatório
              </span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <input
                type="text"
                name="name"
                id="name"
                maxlength="25"
                placeholder="Nome impresso no cartão"
                class="form-control"
                formControlName="name"
              />
              <span
                class="text text-danger"
                *ngIf="
                  rechargeForm.get('name').touched &&
                  rechargeForm.get('name').errors?.required
                "
              >
                Nome impresso no cartão é obrigatório
              </span>
              <span
                class="text text-danger"
                *ngIf="
                  rechargeForm.get('name').touched &&
                  rechargeForm.get('name').errors?.minlength
                "
              >
                Nome impresso no cartão deve ter no mínimo 3 caracteres
              </span>
              <span
                class="text text-danger"
                *ngIf="
                  rechargeForm.get('name').touched &&
                  rechargeForm.get('name').errors?.maxlength
                "
              >
                Nome impresso no cartão deve ter no máximo 25 caracteres
              </span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-md-6">
              <input
                type="text"
                id="due_date"
                name="due_date"
                class="form-control"
                formControlName="due_date"
                maxlength="7"
                placeholder="Vencimento (MM/AAAA)"
                (keypress)="formatDueDate($event)"
                (keyup)="formatDueDate($event)"
                (keydown)="formatDueDate($event)"
              />
              <small
                class="text ms-3"
                [class.text-danger]="
                  rechargeForm.get('due_date').touched &&
                  rechargeForm.get('due_date').errors?.minlength
                "
                ><b>Formato:</b> MM/AAAA</small
              >
            </div>
            <div class="col-md-6">
              <input
                type="number"
                id="cvv"
                name="cvv"
                class="form-control"
                formControlName="cvv"
                maxlength="3"
                placeholder="CVV"
              />
            </div>
          </div>
        </ng-template>

        <div class="services">
          <div class="row">
            <div class="col text-center">
              <p class="total">
                <span
                  >{{
                    rechargeForm.get("credits")?.value | numberFormat
                  }}
                  créditos x {{ price | numberFormat : "" : true : 3 }}</span
                >
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <p class="total">
                <label>Valor total</label>
                <span class="ms-2">{{
                  total | numberFormat : "R$ " : true
                }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-3 images">
          <div class="col-6">
            <img src="assets/secure.png" alt="Ambiente Seguro" />
          </div>
          <div class="col-6 d-flex">
            <div class="logo-azpag">
              <div class="logo"></div>
              <span>AZPAG</span>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="card-footer">
      <div class="buttons row mt-4=">
        <div class="col-md-6">
          <button
            class="btn mb-3 btn-secondary btn-lg btn-block"
            (click)="backClick()"
          >
            Voltar
          </button>
        </div>
        <div class="col-md-6">
          <button
            class="btn btn-primary btn-lg btn-block"
            (click)="nextClick(modalConfirmation)"
            [disabled]="!isFormValid()"
          >
            {{ tab === "payment" ? "Pagar" : "Próximo" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #modalConfirmation let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        QR Code para pagamento do PIX
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('CLOSE')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <p><img src="{{ pixImage }}" class="w-100" /></p>
      <div *ngIf="pixCode != ''" class="text-center">
        <p><small>Se você não conseguir ler o QR Code, pode usar o código abaixo e usar no "Pix Copia e Cola":</small></p>
        <p>
          <textarea rows="3" class="w-100 form-control text-center">{{pixCode}}</textarea><br>
          <button (click)="pixCopy(pixCode)" class="btn btn-primary"><i class="fa fa-copy"></i> Copiar</button>
        </p>
      </div>
    </div>
  </div>
</ng-template>
