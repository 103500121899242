import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag, Contact } from 'src/app/models/ActiveCampaign';
import { IntegrationActivationStatus } from 'src/app/models/IntegrationActivated';

import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ActiveCampaignService {

  constructor(private http: HttpClient
    ) { }
  
  activate(apiKey: string, apiURL: string): Observable<IntegrationActivationStatus> {
    return this.http.post<IntegrationActivationStatus>(`${environment.apiUrl}/active-campaign/activate`, {
      apiKey,
      apiURL
    })
  }

  createTags(sequenceId: string, name: string, campaignId: string, description?: string): Observable<Tag> {
    return this.http.post<Tag>(`${environment.apiUrl}/active-campaign/tags?sequenceId=${sequenceId}&campaignId=${campaignId}`, {
      name,
      description
    });
  }

  editTags(sequenceId: string, id: string, name: string, campaignId: string, description?: string): Observable<Tag> {
    return this.http.put<Tag>(`${environment.apiUrl}/active-campaign/tags/${id}?sequenceId=${sequenceId}&campaignId=${campaignId}`, {
      id,
      name,
      description
    });
  }

  deleteTags(sequenceId: string, id: string, campaignId: string) {
    return this.http.delete<any>(`${environment.apiUrl}/active-campaign/tags/${id}?sequenceId=${sequenceId}&campaignId=${campaignId}`);
  }
}
