import {Component, TemplateRef, ViewChild} from '@angular/core';
import {AppService} from '../../shared/services/app.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ToastService} from '../../shared/components/toast/toast.service';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss']
})
export class BlacklistComponent {

  @ViewChild('modalAdd') modalTemplate: TemplateRef<any>;
  modal: NgbModalRef;

  filter: string;
  word: string;

  get words(): string[] {
    const words = this.appService.words;
    if (this.filter?.trim()) {
      return words.filter(w => w.toLowerCase().includes(this.filter.trim().toLowerCase()));
    }
    return words;
  }

  constructor(
    private appService: AppService,
    private modalService: NgbModal,
    private toastService: ToastService
  ) {}

  open(): void {
    this.word = '';
    this.modal = this.modalService.open(this.modalTemplate);
  }

  delete(word: string): void {
    const words = this.appService.words
      .filter(w => w !== word)
      .sort((a, b) => a > b ? 1 : -1);
    this.appService.savaBlackList(words).subscribe({
      next: () => this.toastService.success('Palavra excluída com sucesso!'),
      error: () => this.toastService.error('Erro ao excluir palavra. Tente novamente mais tarde!')
    });
  }

  save(): void {
    const words = [ ...this.appService.words, this.word ]
      .sort((a, b) => a > b ? 1 : -1);
    this.appService.savaBlackList(words).subscribe({
      next: () => {
        this.modal.close();
        this.toastService.success('Palavra adicionada com sucesso!');
      },
      error: () => this.toastService.error('Erro ao excluir palavra. Tente novamente mais tarde!')
    });
  }
}
