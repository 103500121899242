<app-page-title icon="fa-money" title="Adicionar Créditos"></app-page-title>

<section class="card elevated mb-2">
    <div class="card-body">
        <form (submit)="save()" [formGroup]="rechargeForm" #myForm="ngForm">
            <div class="row">
              <div class="form-group col-md-12">
                <label class="col-sm-12 col-md-5" for="credits">Tipo de Saldo</label>
                <!-- Select Sms ou Whatsapp -->
                <select
                  name="target"
                  id="target"
                  formControlName="target"
                  class="col-sm-12 col-md-7 form-control"
                >
                  <option selected value="sms">SMS</option>
                  <!-- <option value="whatsapp">Whatsapp</option> -->
                </select>
              </div>
                <div class="form-group col-md-12">
                    <label for="user_id">Usuário:</label>
                    <select name="user_id" id="user_id" class="form-control" formControlName="user_id">
                      <option value="" selected>Selecione um usuário</option>
                      <option *ngFor="let user of users" [value]="user.id">
                        {{ user.name }} - {{ user.email }}
                      </option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                    <label for="payment_type">Forma de Pagamento:</label>
                    <input type="text" name="payment_type" id="payment_type" class="form-control" formControlName="payment_type" maxlength="20" />
                </div>
                <div class="form-group col-md-6">
                    <label for="payment_status">Status:</label>
                    <input type="text" name="payment_status" id="payment_status" class="form-control" formControlName="payment_status" maxlength="20" />
                </div>
                <div class="form-group col-md-4">
                    <label for="credits">Créditos:</label>
                    <input type="number" name="credits" id="credits" class="form-control" formControlName="credits" />
                </div>
                <div class="form-group col-md-4">
                    <label for="total">Valor Pago:</label>
                    <input type="number" name="total" id="total" class="form-control" formControlName="total" />
                </div>
                <div class="form-group col-md-4">
                    <label for="price">Valor por {{ this.getServiceName() }}:</label>
                    <input type="number" name="price" id="price" class="form-control" formControlName="price" readonly />
                </div>
            </div>

            <div class="mt-2">
              <button type="submit" class="btn btn-primary" [disabled]="myForm.invalid">Adicionar</button>
              <button class="btn btn-secondary ms-2" (click)="back()">Voltar</button>
          </div>
        </form>
    </div>
</section>
