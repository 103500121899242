<nav class="navbar navbar-expand navbar-light" [class.is-small]="isSmallDevice" [class.simulation]="user.simulated"
  [class.global]="user.globalActivated">
  <div class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav">
      <li>
        <h3>
          <div>
            <div class="info-conta" *ngIf="isSmallDevice && user.global">
              {{ user.globalActivated ? "Conta Global" : "Conta Nacional" }}
            </div>
            <div class="user-info">
              <p>Olá, {{ user.name }}</p>
              <p class="email">({{ user.email }})</p>
              <p *ngIf="user.simulated">Simulando usuário</p>
            </div>
          </div>

          <button *ngIf="user.simulated" class="btn btn-warning btn-back-user" (click)="backToLoggedInUser()">
            <i class="fa fa-arrow-left"></i>
            {{ isSmallDevice ? "" : "Voltar para usuário logado!" }}
          </button>
        </h3>
      </li>
      <li>
        <button class="btn btn-secondary call4u-animation" ngbTooltip="Ative mensagens de voz: clique aqui e saiba mais"
          placement="left" (click)="openPopup()" *ngIf="!usedCall4U">
          <i class="fa fa-phone"></i>
          {{ isSmallDevice ? "" : "Ative mensagens de voz" }}
        </button>
        <a class="btn btn-secondary" *ngIf="usedCall4U" ngbTooltip="Abrir Call4U em outra aba"
          href="https://app.call4u.com.br/sign-in" target="_blank"
          style="margin-bottom: 8px; background: linear-gradient(45deg, #e9710b, #ff9143);">
          <i class="fa">
            <img src="/assets/call4u.webp" class="call4u-icon" />
          </i>
          {{ isSmallDevice ? "" : "Acessar Call4U" }}
        </a>
      </li>
      <li>
        <button class="btn btn-secondary" ngbTooltip="Programa de afiliados: clique aqui e saiba mais" placement="left"
          routerLink="/affiliates" *ngIf="(isClient && isAffiliate) || isAdministrator">
          <i class="fa fa-bullhorn"></i>
          {{ isSmallDevice ? "" : "Programa de Afiliados" }}
        </button>
      </li>
      <li class="nav-item">
        <div class="notification" (click)="toggleNotifications(true)" (mouseover)="stopCloseNotifications()"
          (mouseout)="toggleNotifications()">
          <i class="fa fa-2x fa-bell-o"></i>
          <span class="badge">{{ notifications.length }}</span>

          <div class="notification-items" [style]="{ display: showNotifications ? 'flex' : 'none' }">
            <h2>
              Notificações
              <i class="counter" *ngIf="selectedNotifications.length > 0">{{
                selectedNotifications.length
                }}</i>
              <i class="fa fa-check-square-o" ngbTooltip="Marcar todas como lida" placement="bottom-left"
                (click)="markAllRead()"></i>
              <i class="fa fa-ellipsis-v" *ngIf="selectedNotifications.length > 0"></i>
            </h2>
            <div class="container">
              <div class="notification-item" [class.fa-bold]="notification.read"
                *ngFor="let notification of notifications.reverse()">
                <label>
                  [{{ notification.created_at | date : "dd/MM/yyyy HH:mm" }}]
                </label>
                <p>{{ notification.message }}</p>
                <i class="fa fa-check" ngbTooltip="Marcar como lida" placement="top-right"
                  (click)="markAsRead(notification)"></i>
              </div>
            </div>
            <div class="notification-item no-alert" *ngIf="notifications.length === 0">
              Você não tem nenhuma notificação =)
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown">
        <div class="icons">
          <div class="icon" (click)="toggleDropdown()" (mouseout)="startCloseTime()" (mouseover)="stopCloseTime()">
            <img src="/assets/user-photo.png" alt="" />
            <span>
              <i class="fa fa-arrow-down"></i>
            </span>
          </div>
        </div>

        <div class="dropdown-menu" [class.show]="showDropdown" (mouseout)="startCloseTime()"
          (mouseover)="stopCloseTime()" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" routerLink="/change-password" (mouseout)="startCloseTime()"
            (mouseover)="stopCloseTime()">
            <i class="fa fa-key"></i>
            Alterar senha
          </a>
          <a class="dropdown-item" routerLink="/profile" (mouseout)="startCloseTime()" (mouseover)="stopCloseTime()">
            <i class="fa fa-user"></i>
            Meus dados
          </a>

          <a *ngIf="user.api_enabled" class="dropdown-item" routerLink="/profile/api" (mouseout)="startCloseTime()" (mouseover)="stopCloseTime()" >
            <i class="fa fa-cloud"></i>
            API
          </a>

          <a class="dropdown-item" routerLink="/" (click)="logout()" (mouseout)="startCloseTime()"
            (mouseover)="stopCloseTime()">
            <i class="fa fa-power-off"></i>
            Sair
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>

<call4u-activate-popup *ngIf="togglePopUp" [onClose]="closeCall4U"></call4u-activate-popup>