import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { User } from 'src/app/models/User';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verification-message-whatsapp',
  templateUrl: './verification-message-whatsapp.component.html',
  styleUrls: ['./verification-message-whatsapp.component.scss']
})
export class VerificationMessageWhatsappComponent implements OnInit {
  
  get supportLink() {
    return environment.supportLink;
  }

  user: User;
  
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
  }

}
