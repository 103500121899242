import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

import {User} from '../../../models/User';
import {AuthService} from '../../../shared/services/auth.service';
import {MenuItem} from './menu-item';
import {MenuService} from './menu.service';
import {ADMINISTRATOR} from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  get supportLink(): string {
    return environment.supportLink;
  }

  get baseUrl(): string {
    return environment.baseUrl;
  }

  get isAdministrator(): boolean {
    return this.user.profile_id === ADMINISTRATOR;
  }

  layoutChanges: Observable<any>;

  title: string;
  menuItens: MenuItem[] = [];

  isVisible: boolean;
  isSmallDevice: boolean;
  adminMenuVisible = false;

  user?: User;
  constructor(
    private authService: AuthService,
    private menuService: MenuService,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();

    if (!['app.smsconvert.com', 'app.smsfunnel.com.br'].includes(window.location.host)) {
      this.title = environment.title;
      window.document.title = environment.title + ' - SMS Funnel';
    }

    this.adminMenuVisible = window.location.href.includes('tutoriais');
    this.adminMenuVisible = window.location.href.includes('blacklist');

    this.menuService
      .getMenuVisibility()
      .subscribe(isVisible => this.isVisible = isVisible);

    this.loadMenuItems();

    this.layoutChanges = this.breakpointObserver
      .observe(['(max-width: 915px)']);

    this.layoutChanges.subscribe(result => {
      this.isSmallDevice = result.matches;
      this.menuService.setSmallDevice(this.isSmallDevice);
      if (this.isSmallDevice) {
        this.menuService.hide();
      } else {
        this.menuService.show();
      }
    });

    this.authService.userChanged.subscribe(user => {
      this.loadMenuItems();
      this.user = user;
    });
  }

  loadMenuItems(): void {
    this.menuItens = this.menuService.getMenuItems();
  }

  toggleMenu(): void {
    this.menuService.toggle();
  }

  toggleAdminMenu(): void {
    this.adminMenuVisible = !this.adminMenuVisible;
  }

  toggleGlobalAccount(): void {
    this.authService.toggleGlobalAccount();
  }
}
