<app-page-title title="Tutoriais" icon="fa-files-o">
  <button type="button" class="btn btn-success" (click)="open()">Adicionar</button>
</app-page-title>

<div class="card" cdkDropList [cdkDropListData]="tutoriais" (cdkDropListDropped)="drop($event)">

    <div class="form-group">
      <input type="text" class="form-control" [(ngModel)]="filter" placeholder="Pesquisar tutorial" />
    </div>

    <div class="alert alert-info">
      Quando você não estivar pesquisando, você pode arrastar os itens para alterar ordem.
    </div>

    <div *ngFor="let tutorial of filtered; let i = index" class="d-flex align-items-center mt-1 ps-2"
         cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="!canDragAndDrop">
      <div class="move-placeholder" *cdkDragPlaceholder></div>
      <span class="flex-grow-1">{{ tutorial.title }}</span>
      <button type="button" class="btn btn-success me-2" (click)="edit(tutorial)"><i class="fa fa-edit"></i></button>
      <button type="button" class="btn btn-danger" (click)="delete(tutorial)"><i class="fa fa-trash"></i></button>
    </div>
</div>

<ng-template #modalAdd let-modalAdd>
  <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modalAdd.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label>URL:</label>
        <input type="text" class="form-control" [(ngModel)]="url" (change)="verifyUrl()" />
      </div>

      <div class="form-group">
        <label>Título:</label>
        <input type="text" class="form-control" [(ngModel)]="tutorial.title" />
      </div>

      <iframe [src]="tutorial.url" *ngIf="tutorial.url"></iframe>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="save()" [disabled]="!tutorial.url">Salvar</button>
      <button type="button" class="btn btn-outline-primary" (click)="modalAdd.dismiss()">Fechar</button>
    </div>
  </div>
</ng-template>

<script src="https://player.vimeo.com/api/player.js"></script>
