import { ToastService } from './../../shared/components/toast/toast.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { User } from 'src/app/models/User';
import { AuthService } from './../../shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  get supportLink() {
    return environment.supportLink + '?text=Minha conta foi bloqueada por tentativas de acesso com senha inválidas.';
  }

  title = '';
  loginForm: UntypedFormGroup;
  passwordAttemptsError = false;
  utmParams: string;
  private subscriptions: Subscription[] = [];

  @ViewChild('inputEmail') inputEmail: ElementRef<HTMLInputElement>;

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if ( !['app.smsconvert.com', 'app.smsfunnel.com.br'].includes(window.location.host) ) {
      this.title = 'DEV';
      window.document.title = 'DEV - SMS Funnel';
    }

    if (this.authService.isLogged()) {
      this.router.navigate(['/']);
      return;
    }

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      utm: ['']
    });

    this.subscriptions.push(this.queryParams());
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputEmail.nativeElement.focus();
    }, 200);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  queryParams(): Subscription {
    return this.route.queryParams.subscribe(params => {
      const allEntries = Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`);
      
      this.utmParams = `?${allEntries.join('&')}`;
      this.loginForm.patchValue({ utm: this.utmParams });
    });
  }

  login(): void {
    if (!this.loginForm && this.loginForm.valid) {
      this.toastService.error('Preencha um E-mail e Senha válidos para acessar!');
      this.ngAfterViewInit();
      return;
    }

    const user = this.loginForm.getRawValue() as User;
    this.authService
      .login(user)
      .subscribe(
        (loggedUser) => {
          if (loggedUser?.user?.beta) {
            this.authService.loginBeta(user).subscribe((loggedUserBeta) => {
              const userData = JSON.stringify(loggedUserBeta);
              const redirectUrl = `${environment.baseBetaUrl}/#/login?userData=${encodeURIComponent(userData)}`;
              window.location.href = redirectUrl;
            });
          } else {
            this.router.navigate(['/']);
          }
        },
        err => {
          const { status, error } = err;
          if (error.user_blocked) {
            this.toastService.error('3 tentativas inválidas, usuário bloqueado!');
            this.passwordAttemptsError = true;
          }
          if (status !== 0 && !error.user_blocked) {
            this.toastService.error('Usuário/Senha inválido(s)!');
            this.passwordAttemptsError = false;
          }
        }
      );
  }

}
