import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgApexchartsModule} from 'ng-apexcharts';
import {TagInputModule} from 'ngx-chips';

import {NumberFormaterPipe} from '../shared/pipes/NumberFormaterPipe';
import {ListsService} from '../shared/services/lists.service';
import {SharedModule} from '../shared/shared.module';
import {MaterialModule} from '../shared/material.module';
import {TokenInterceptor} from '../shared/interceptors/token.interceptor';
import {AffiliatesComponent} from './affiliates/affiliates.component';
import {BroadcastComponent} from './broadcasts/broadcast/broadcast.component';
import {BroadcastsComponent} from './broadcasts/broadcasts.component';
import {ViewBroadcastComponent} from './broadcasts/view-broadcast/view-broadcast.component';
import {BroadcastWhatsappComponent} from './broadcasts-whatsapp/broadcast-whatsapp/broadcast-whatsapp.component';
import {BroadcastsWhatsappComponent} from './broadcasts-whatsapp/broadcasts-whatsapp.component';
import {ViewBroadcastWhatsappComponent} from './broadcasts-whatsapp/view-broadcast-whatsapp/view-broadcast-whatsapp.component';
import {CampaignComponent} from './campaigns/campaign/campaign.component';
import {CampaignsComponent} from './campaigns/campaigns.component';
import {SequencesComponent} from './campaigns/sequences/sequences.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {AccountConfirmationComponent} from './account-confirmation/account-confirmation.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {HelpComponent} from './help/help.component';
import {IntegrationComponent} from './integrations/integration/integration.component';
import {IntegrationsComponent} from './integrations/integrations.component';
import {PostbacksComponent} from './integrations/postbacks/postbacks.component';
import {LayoutModule} from './layout/layout.module';
import {LeadPostbackComponent} from './leads/lead-postback/lead-postback.component';
import {LeadSequencesComponent} from './leads/lead-sequences/lead-sequences.component';
import {LeadsComponent} from './lists/leads/leads.component';
import {ListComponent} from './lists/list/list.component';
import {ListsComponent} from './lists/lists.component';
import {LoginComponent} from './login/login.component';
import {MessagesComponent} from './messages-report/messages/messages.component';
import {ModalTwilioComponent} from './modal-twilio/modal-twilio.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {PagesRoutingModule} from './pages-routing.module';
import {ModalPixKeyComponent} from './partner-dashboard/modal-pix-key/modal-pix-key.component';
import {PartnerDashboardComponent} from './partner-dashboard/partner-dashboard.component';
import {PartnerManagementComponent} from './partner-management/partner-management.component';
import {PartnerPaymentsComponent} from './partner-payments/partner-payments.component';
import {PasswordResetStartComponent} from './password-reset-start/password-reset-start.component';
import {PasswordResetSuccessComponent} from './password-reset-success/password-reset-success.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {ProfileComponent} from './profile/profile.component';
import {AddCreditsComponent} from './recharges/add-credits/add-credits.component';
import {RechargeComponent} from './recharges/recharge/recharge.component';
import {RechargesComponent} from './recharges/recharges.component';
import {ReportsComponent} from './reports/reports.component';
import {SupportFormComponent} from './support-form/support-form.component';
import {ClientsComponent} from './users/clients/clients.component';
import {UserComponent} from './users/user/user.component';
import {UsersComponent} from './users/users.component';
import {ViewPartnerPaymentsComponent} from './view-partner-payments/view-partner-payments.component';
import {LeadResponsesComponent} from './lead-responses/lead-responses.component';
import {FinancialComponent} from './financial/financial.component';
import {AnalyticsComponent} from './analytics/analytics.component';
import {AnalyticsCampaignsComponent} from './analytics/campaigns/campaigns.component';
import {AnalyticsCampaignsDetailsComponent} from './analytics/campaigns/detail/detail.component';
import {AnalyticsSalesComponent} from './analytics/sales/sales.component';
import {MessagesReportComponent} from './messages-report/messages-report.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ShortUrlAnalysisComponent} from './short-url-analysis/short-url-analysis.component';
import { ProviderComponent } from './providers/providers.component';
import { ConsumptionReport } from './consumption-report/consumption-report.component';
import { BroadcastsFilterComponent } from './broadcasts-filter/broadcasts-filter.component';
import { SmsValidationComponent } from './sms-validation/sms-validation.component';
import { TutoriaisComponent } from './tutoriais/tutoriais.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { BlacklistComponent } from './blacklist/blacklist.component';
import { RedirectSignupComponentComponent } from './redirect-signup-component/redirect-signup-component.component';

@NgModule({
  declarations: [
    CampaignComponent,
    CampaignsComponent,
    ChangePasswordComponent,
    AccountConfirmationComponent,
    DashboardComponent,
    IntegrationComponent,
    IntegrationsComponent,
    LeadsComponent,
    ListsComponent,
    ListComponent,
    LoginComponent,
    NotificationsComponent,
    NumberFormaterPipe,
    RechargeComponent,
    RechargesComponent,
    SequencesComponent,
    BroadcastsComponent,
    BroadcastComponent,
    ViewBroadcastComponent,
    BroadcastsWhatsappComponent,
    BroadcastWhatsappComponent,
    ViewBroadcastWhatsappComponent,
    NotFoundComponent,
    UsersComponent,
    UserComponent,
    MessagesComponent,
    MessagesReportComponent,
    ReportsComponent,
    PasswordResetComponent,
    PasswordResetSuccessComponent,
    PasswordResetStartComponent,
    HelpComponent,
    SupportFormComponent,
    AddCreditsComponent,
    AffiliatesComponent,
    PostbacksComponent,
    LeadSequencesComponent,
    LeadPostbackComponent,
    PartnerDashboardComponent,
    ModalPixKeyComponent,
    PartnerPaymentsComponent,
    ClientsComponent,
    ProfileComponent,
    PartnerManagementComponent,
    ViewPartnerPaymentsComponent,
    ModalTwilioComponent,
    LeadResponsesComponent,
    AnalyticsComponent,
    AnalyticsCampaignsComponent,
    AnalyticsCampaignsDetailsComponent,
    AnalyticsSalesComponent,
    FinancialComponent,
    ShortUrlAnalysisComponent,
    ProviderComponent,
    ConsumptionReport,
    BroadcastsFilterComponent,
    SmsValidationComponent,
    TutoriaisComponent,
    BlacklistComponent,
    RedirectSignupComponentComponent
  ],
    imports: [
        CommonModule,
        DragDropModule,
        NgbModule,
        HttpClientModule,
        FormsModule,
        LayoutModule,
        PagesRoutingModule,
        ReactiveFormsModule,
        SharedModule,
        MaterialModule,
        TagInputModule,
        NgApexchartsModule,
        MatTooltipModule
    ],
  providers: [
    ListsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class PagesModule { }
