import {Component, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {HelpService, Video} from '../help/help.service';
import {ToastService} from '../../shared/components/toast/toast.service';
import {ConfirmationService} from '../../shared/components/confirmation-modal/confirmation.service';
import {moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-tutoriais',
  templateUrl: './tutoriais.component.html',
  styleUrls: ['./tutoriais.component.scss']
})
export class TutoriaisComponent {

  @ViewChild('modalAdd') modalTemplate: TemplateRef<any>;
  modal: NgbModalRef;

  id: string;
  url: string;
  tutorial: Video;
  filter: string;

  tutoriais: Video[] = [];

  get modalTitle(): string {
    return (this.id ? 'Alterar' : 'Adicionar') + ' tutorial';
  }

  get filtered(): Video[] {
    if (this.filter?.trim()) {
      return this.tutoriais.filter(v => v.title.toLowerCase().includes(this.filter.trim().toLowerCase()));
    }

    return this.tutoriais;
  }

  get canDragAndDrop(): boolean {
    return this.tutoriais.length === this.filtered.length;
  }
  constructor(
    private modalService: NgbModal,
    private helpService: HelpService,
    private toastService: ToastService,
    private confirmation: ConfirmationService
  ) {
    this.helpService.tutorial().subscribe(t => this.tutoriais = t);
  }

  open(tutorial: Video = null): void {
    this.id = null;
    this.url = null;
    this.tutorial = {
      title: '',
      id: ''
    };

    if (tutorial) {
      this.id = tutorial.id;
      this.tutorial = this.helpService.addSafeUrl(tutorial);
      this.url = this.tutorial.url['changingThisBreaksApplicationSecurity'];
    }
    console.log(this.id);
    this.modal = this.modalService.open(this.modalTemplate, { size: 'lg' });
  }

  save(): void {
    if (!this.id && !this.tutorial.id) {
      this.toastService.error('URL Inválida!');
      return;
    }

    let position = -1;
    let successMessage = 'Tutorial alterado com sucesso!';
    let errorMessage = 'Erro ao alterar tutorial!';

    const tutoriais = [...this.tutoriais];
    if (this.id) {
      position = tutoriais.findIndex(t => t.id === this.id);
      if (position === -1) {
        this.toastService.error('Vídeo inválido');
      }

      tutoriais[position] = this.tutorial;
    } else {
      tutoriais.push(this.tutorial);
      successMessage = successMessage.replace('alterado', 'incluído');
      errorMessage = errorMessage.replace('alterar', 'incluir');
    }

    this.helpService.save(tutoriais)
      .subscribe({
        next: () => {
          this.tutoriais = tutoriais;
          this.toastService.success(successMessage);
          this.modal.close();
        },
        error: () => this.toastService.error(errorMessage)
      });
  }

  edit(tutorial: Video): void {
    this.open({...tutorial});
  }

  delete(tutorial: Video): void {
    this.confirmation.show(`Deseja realmente excluir o tutorial ${tutorial.title}?`, () => {
      const tutoriais = [...this.tutoriais].filter(v => v.id !== tutorial.id);
      this.helpService.save(tutoriais)
        .subscribe({
          next: () => {
            this.tutoriais = tutoriais;
            this.toastService.success('Tutorial excluído com sucesso!');
            this.modal.close();
          },
          error: () => this.toastService.error('Erro ao excluir tutorial')
        });
    });
  }

  verifyUrl(): void {
    this.tutorial.url = null;
    this.tutorial = this.helpService.create(this.url, this.tutorial);
    if (!this.tutorial.url) {
      this.toastService.error('Você só pode adicionar links do YouTube ou Vimeo!');
    }
  }

  drop(event: any): void {
    if (this.canDragAndDrop) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.helpService.save(this.tutoriais).subscribe({
        next: () => this.toastService.success('Ordem alterada com sucesso!'),
        error: () => this.toastService.error('Erro ao modificar ordem dos tutoriais. Tente novamente mais tarde!')
      });
    } else {
      this.toastService.info('Você só pode mover quando não estiver pesquisando!');
    }
  }
}
