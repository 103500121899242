import {BroadcastContact} from '../../models/BroadcastContact';

import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {environment} from '../../../environments/environment';

import {take} from 'rxjs/operators';
import {Broadcast} from 'src/app/models/Broadcast';
import {CANCELLED, IMPORTING} from 'src/app/models/BroadcastStatus';
import {removeInvalidCharacters} from '../utils';
import {PaginatedResult} from '../model/paginated-result';
import {BroadcastFilter} from '../../models/BroadcastFilter';

const importFinished = new EventEmitter<Broadcast>();

@Injectable({ providedIn: 'root' })
export class BroadcastsService {
  get importFinished(): EventEmitter<Broadcast> {
    return importFinished;
  }

  constructor(private http: HttpClient) { }

  isImporting(broadcast: Broadcast): boolean {
    return broadcast.importing || broadcast.broadcast_status_id === IMPORTING;
  }

  isCancelled(broadcast: Broadcast): boolean {
    return broadcast.error || broadcast.broadcast_status_id === CANCELLED;
  }

  findById(id: string): Observable<Broadcast> {
    return this.http.get<Broadcast>(`${environment.apiUrl}/broadcasts/${id}`);
  }

  status(id: string): Observable<Broadcast> {
    const url = `${environment.apiUrl}/broadcasts/${id}/status?no-loading=true`;
    return this.http.get<Broadcast>(url).pipe(take(1));
  }

  listContacts(id: string, page: number = 1, perPage: number = 15, phone?: string): Observable<PaginatedResult<BroadcastContact[]>> {
    let url = `${environment.apiUrl}/broadcasts/${id}/contacts?page=${page}&per_page=${perPage}`;
  
    if (phone) {
      url += `&phone=${phone}`;
    }
  
    return this.http.get<PaginatedResult<BroadcastContact[]>>(url);
  }

  listPaginated(page: number = 1, perPage: number = 15, filter: BroadcastFilter): Observable<PaginatedResult<Broadcast[]>> {
    let url = `${environment.apiUrl}/broadcasts?page=${page}&per_page=${perPage}`;

    if (filter.startDate) {
      url += `&startDate=${filter.startDate}`;
    }

    if (filter.endDate) {
      url += `&endDate=${filter.endDate}`;
    }

    if (filter.text) {
      url += `&text=${filter.text}`;
    }

    if (filter.order) {
      url += `&orderBy=${filter.order}`;
    }

    if (filter.orderDirection) {
      url += `&invertedOrder=${filter.orderDirection}`;
    }

    if (filter.author) {
      url += `&author=${filter.author}`;
    }

    return this.http.get<PaginatedResult<Broadcast[]>>(url);
  }

  send(broadcast: Broadcast): Observable<Broadcast> {
    broadcast.message = removeInvalidCharacters(broadcast.message);
    return this.http.post<Broadcast>(`${environment.apiUrl}/broadcasts`, broadcast);
  }

  update(broadcast: Broadcast): Observable<Broadcast> {
    return this.http.put<Broadcast>(`${environment.apiUrl}/broadcasts/${broadcast.id}`, broadcast);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/broadcasts/${id}`);
  }

  cancel(id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/broadcasts/${id}/cancel`, {});
  }

  getTotalSent(statisticsStartDate = null, statisticsEndDate = null): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/broadcasts/total-sent?endDate=${statisticsEndDate}&startDate=${statisticsStartDate}`);
  }

  resend(broadcastId: string, providerAccountId: string): Observable<Broadcast> {
    return this.http.post<Broadcast>(`${environment.apiUrl}/broadcasts/resend`, { broadcastId, providerAccountId });
  }

  getAllBroadcasts(
    page: number = 1,
    perPage: number = 15,
    startDate?: string,
    endDate?: string,
    message?: string,
    orderBy?: string,
    invertedOrder?: boolean,
    author?: string,
    providerAccountId?: string,
  ): Observable<PaginatedResult<Broadcast[]>> {
    let url = `${environment.apiUrl}/all-broadcasts?page=${page}&per_page=${perPage}`;

    if (startDate) {
      url += `&start_date=${startDate}`;
    }

    if (endDate) {
      url += `&end_date=${endDate}`;
    }

    if (author) {
      url += `&author=${author}`;
    }

    if (message) {
      url += `&message=${message}`;
    }

    if (orderBy) {
      url += `&orderBy=${orderBy}`;
    }

    if (invertedOrder) {
      url += `&invertedOrder=${invertedOrder}`;
    }

    if (providerAccountId) {
      url += `&provider_account_id=${providerAccountId}`;
    }

    return this.http.get<PaginatedResult<Broadcast[]>>(url);
  }

  getAllBroadcastsToValidate(
    page: number = 1,
    perPage: number = 15,
    filter: BroadcastFilter
  ): Observable<PaginatedResult<Broadcast[]>> {
    let url = `${environment.apiUrl}/broadcasts/list/validate?no-loading=&page=${page}&per_page=${perPage}`;

    if (filter.startDate) {
      url += `&start_date=${filter.startDate}`;
    }

    if (filter.endDate) {
      url += `&end_date=${filter.endDate}`;
    }

    if (filter.author) {
      url += `&author=${filter.author}`;
    }

    if (filter.text) {
      url += `&name=${filter.text}`;
    }

    if (filter.order || filter.orderBy) {
      url += `&order_by=${filter.order ?? filter.orderBy}`;
    }

    url += `&pending_only=${filter.pendingOnly}`;
    url += `&inverted_order=${filter.orderDirection}`;

    return this.http.get<PaginatedResult<Broadcast[]>>(url);
  }

  approveBroadcast(id: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/broadcasts/${id}/approve`, {});
  }

  disapproveBroadcast(id: string, reason: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/broadcasts/${id}/cancel`, { reason });
  }
}
