<ng-template #modalUpdateBillingInfos let-modal>
  <div class="modal-header">
    <span *ngIf="step == 0">Dados de faturamento</span>
    <span *ngIf="step >= 1">Informe seus dados</span>
  </div>
  
  <div class="modal-body">
    <div *ngIf="step == 0">
      <h6 class="question">Seus dados de faturamento são os mesmos já informados anteriormente em sua conta?</h6>
    
      <div class="infos-container">
        <div class="infos-subcontainer">
          <span ngbTooltip="Nome">{{ user.name }}</span>
          <span ngbTooltip="Email">{{ user.email ?? 'Sem informações de e-mail' }}</span>
          <span ngbTooltip="Telefone">{{ user.phone ?? 'Sem informações de telefone' }}</span>
        </div>
        <hr style="background-color: white; color: white; width: 1px; height: 100px;">
        <div class="infos-subcontainer">
          <span ngbTooltip="Endereço">{{ user.street && user.number ? user.street + ', ' + user.number : 'Sem informações de endereço' }}</span>
          <span ngbTooltip="Bairro">{{ user.district && user.city && user.region ? user.district + ', ' + user.city + ' - ' + user.region : 'Sem informações de região'}}</span>
          <span ngbTooltip="CEP">{{ user.postcode ?? 'Sem informações de CEP' }}</span>
        </div>
      </div>
    </div>

    <div *ngIf="step >= 1">
      <div class="step-title">
        <span class="step-number">{{ step }}</span>
        <h6>{{ stepsTitles[step] }}</h6>
      </div>
      <form [formGroup]="userForm" (submit)="updateBillingInfos()">
        <div class="form-group col" style="margin-top: 30px" *ngIf="step == 1">
          <label for="billing_document">CPF/CNPJ</label>
          <input type="text" (input)="formatCpfCnpj($event)" (change)="formatCpfCnpj($event)" maxlength="18" id="billing_document" required class="form-control" formControlName="billing_document" />
        </div>
        <div class="form-group col" *ngIf="step == 1">
          <label for="billing_email">E-mail</label>
          <input type="text" id="billing_email" class="form-control" formControlName="billing_email" />
        </div>
        <div class="form-group col" *ngIf="step == 2">
          <label for="billing_company_name">Razão social</label>
          <input type="text" id="billing_company_name" class="form-control" formControlName="billing_company_name" />
        </div>
        <div class="form-group col" *ngIf="step == 2">
          <label for="billing_company_alias">Nome fantasia</label>
          <input type="text" id="billing_company_alias" class="form-control" formControlName="billing_company_alias" />
        </div>
        <div class="form-group col" *ngIf="step == 2">
          <label for="billing_im">Inscrição municipal</label>
          <input type="text" id="billing_im" class="form-control" formControlName="billing_im" />
        </div>
        <div class="form-group col" *ngIf="step == 3">
          <label for="billing_postcode">CEP</label>
          <input type="text" id="billing_postcode" (input)="cepChanged($event)" class="form-control" maxlength="9" formControlName="billing_postcode" />
          <small *ngIf="errorCEP" style="color: rgb(255, 77, 77);">Não foi possível puxar os dados do CEP automaticamente. Por favor, verifique se a numeração está correta.</small>
        </div>
        <div class="form-group col" *ngIf="step == 3">
          <label for="billing_street">Endereço</label>
          <input type="text" id="billing_street" class="form-control" formControlName="billing_street" />
        </div>
        <div class="form-group col" *ngIf="step == 3">
          <label for="billing_number">Número</label>
          <input type="number" id="billing_number" class="form-control" formControlName="billing_number" />
        </div>
        <div class="form-group col" *ngIf="step == 4">
          <label for="billing_district">Bairro</label>
          <input type="text" id="billing_district" class="form-control" formControlName="billing_district" />
        </div>
        <div class="form-group col" *ngIf="step == 4">
          <label for="billing_city">Cidade</label>
          <input type="text" id="billing_city" class="form-control" formControlName="billing_city" />
        </div>
        <div class="form-group col" *ngIf="step == 4">
          <label for="billing_state">Estado</label>
          <input type="text" id="billing_state" class="form-control" formControlName="billing_state" />
        </div>
        <div class="form-group col" *ngIf="step == 4">
          <label for="billing_complement">Complemento (Opcional)</label>
          <input type="text" id="billing_complement" class="form-control" formControlName="billing_complement" />
        </div>
      </form>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-no" *ngIf="step == 0" (click)="nextStep()">
      Não, informar dados.
    </button>
    <button class="btn btn-yes" *ngIf="step == 0" (click)="updateBillingInfos()">
      Sim, usar os mesmos.
    </button>

    <button class="btn" *ngIf="step >= 1 && step <= 4" (click)="lastStep()">
      Voltar
    </button>
    <button class="btn btn-yes" *ngIf="step >= 1 && step < 4" (click)="nextStep()">
      Avançar
    </button>
    <button class="btn btn-yes" *ngIf="step == 4" (click)="updateBillingInfos()">
      Finalizar
    </button>
  </div>
</ng-template>