import { menuItens } from './menu-items';
import { MenuItem } from './menu-item';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthService } from '../../../shared/services/auth.service';

const MENU_KEY = 'SMSFUNNEL::MENU';

@Injectable()
export class MenuService {

  isVisible = true;
  showMenu = new BehaviorSubject<boolean>(this.isVisible);
  isSmallDevice = false;

  private isSmallDeviceSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService
  ) {
    const visibility = this.getSavedVisibility();
    if (visibility !== '') {
      this.isVisible = JSON.parse(visibility);
      this.showMenu.next(this.isVisible);
    }
  }

  getMenuItems(): MenuItem[] {
    const user = this.authService.getUser();

    let authorizedMenuItems = menuItens.filter(item => item.profiles.includes(user.profile_id));

    if (user.simulated) {
      authorizedMenuItems = authorizedMenuItems.filter(item => item.simulated);
    }

    if (user.globalActivated) {
      authorizedMenuItems = authorizedMenuItems.filter(item => item.global);
    }

    if (!user.analytics_sync) {
      authorizedMenuItems = authorizedMenuItems.filter(item => !item.path.includes('analytics'));
    }

    return authorizedMenuItems;
  }

  getMenuVisibility(): Observable<boolean> {
    return this.showMenu.asObservable();
  }

  getIsSmallDevice(): Observable<boolean> {
    return this.isSmallDeviceSubject.asObservable();
  }

  hide(): void {
    if (this.isVisible) {
      this.toggle();
    }
  }

  show(): void {
    if (!this.isVisible) {
      this.toggle();
    }
  }

  toggle(): void {
    this.isVisible = !this.isVisible;
    this.showMenu.next(this.isVisible);
    this.saveVisibility();
  }

  setSmallDevice(isSmallDevice: boolean): void {
    this.isSmallDevice = isSmallDevice;
    this.isSmallDeviceSubject.next(isSmallDevice);
  }

  private getSavedVisibility(): string {
    return localStorage.getItem(MENU_KEY);
  }

  private saveVisibility(): void {
    localStorage.setItem(MENU_KEY, JSON.stringify(this.isVisible));
  }

}
