import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import PlatformPattern from 'src/app/models/PlatformPattern';

export const CROSS_CHECKOUT_URLS_PATTERNS: PlatformPattern[] = [
  { platform: 'APPMAX', patterns: [/https:\/\/spinmax\.carrinho\.app\/one-checkout\/ocmtb\/\d+/]},
  { platform: 'HOTMART', patterns: [/https:\/\/pay\.hotmart\.com\/[^\?\/]+(\?bid=\d+)?(\/\d+)?/] },
  { platform: 'PERFECTPAY', patterns: [/https?:\/\/go\.perfectpay\.com\.br\/pay\/[A-Z0-9]+(\?[a-zA-Z0-9&=]*)?/]}

]

export function platformPatternValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const url = control.value;

    const platform = detectPlatformByPattern(url);

    if (!url || testPlatformPattern(platform, url)) {
      return null; // URL válida, retorna null para indicar que é válida
    } else {
      return { invalidUrl: true }; // URL inválida, retorna um objeto com a chave 'invalidUrl'
    }
  };
}

// Função usada para detectar a plataforma de acordo com o Regex / Pattern da URL fornecida.
// CROSS_CHECKOUT_URLS_PATTERNS é a lista de definições de plataforma x seus patterns/regexes.
export const detectPlatformByPattern = (url: string) : string => {
  for (const platform of CROSS_CHECKOUT_URLS_PATTERNS) {
    for (const pattern of platform.patterns) {
      if (pattern.test(url)) {
        return platform.platform;
      }
    }
  }
  return null;
}


// Testar se a URL fornecida bate com os patterns/regexes definidos para a plataforma
// Função se autoexplica.
export const testPlatformPattern = (platform: string, url: string): boolean => {
  const platformPatterns = CROSS_CHECKOUT_URLS_PATTERNS.find(
    (p) => p.platform === platform
  );

  if (platformPatterns) {
    for (const pattern of platformPatterns.patterns) {
      return pattern.test(url)
    }
  }

  return false;
}