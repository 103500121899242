import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../models/User';
import { ToastService } from '../toast/toast.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'update-billing-infos-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class UpdateBillingInfosPopupComponent implements OnInit, AfterViewInit {
  @ViewChild('modalUpdateBillingInfos', {static: true}) modalUpdateBillingInfos: ElementRef<any>;
  @Input() onClose: CallableFunction;

  modalRef: any
  modalErrorMessage: string
  user: User
  userForm: UntypedFormGroup;
  step: number = 0
  stepsTitles = ['', 'Dados da Empresa', 'Dados da Empresa', 'Endereço de Cobrança', 'Endereço de Cobrança']
  oldCNPJCPF: string = '';
  errorCEP: boolean = false;

  docs_url: string
  login_url: string

  constructor(
    private modalService: NgbModal,
    private toastService: ToastService,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.new(this.modalUpdateBillingInfos);
    this.user = this.authService.getUser();
    this.userForm = this.fb.group({
      id: [this.user.id],
      name: [this.user.name, [Validators.required, Validators.minLength(3)]],
      email: [this.user.email],
      phone: [this.user.phone, [Validators.required]],
      document: [this.user.document, [Validators.required]],
      pix_key: [this.user.pix_key],
      utm_source: [this.user.utm_source],
      password: [''],
      operation_type: [this.user.operation_type],
      postcode: [
        this.user.postcode,
        [Validators.required, Validators.minLength(9), Validators.maxLength(9)],
      ],
      street: [
        this.user.street,
        [Validators.required, Validators.minLength(3)],
      ],
      number: [this.user.number, Validators.required],
      complement: [this.user.complement],
      district: [
        this.user.district,
        [Validators.required, Validators.minLength(3)],
      ],
      city: [this.user.city, Validators.required],
      region: [this.user.region, Validators.required],
      billing_document: [this.user.billing_document],
      billing_company_name: [this.user.billing_company_name],
      billing_company_alias: [this.user.billing_company_alias],
      billing_email: [this.user.billing_email],
      billing_im: [this.user.billing_im],
      billing_postcode: [this.user.billing_postcode],
      billing_street: [this.user.billing_street],
      billing_number: [this.user.billing_number],
      billing_complement: [this.user.billing_complement],
      billing_district: [this.user.billing_district],
      billing_city: [this.user.billing_city],
      billing_state: [this.user.billing_state]
    });
  }

  new(modal: any) {
    this.modalRef = this.modalService.open(modal, {backdrop: 'static', windowClass: 'modal-billing', keyboard: false});
  }

  lastStep() {
    this.step--
  }
  
  nextStep() {
    switch (this.step) {
      case 1:
        if (!this.userForm.get('billing_document')?.value || !this.userForm.get('billing_email')?.value) {
          return this.toastService.error('Preencha os campos antes de prosseguir.');
        }
        break;
      case 2:
        if (!this.userForm.get('billing_company_name')?.value || !this.userForm.get('billing_company_alias')?.value || !this.userForm.get('billing_im')?.value) {
          return this.toastService.error('Preencha os campos antes de prosseguir.');
        }
        break;
      case 3:
        if (!this.userForm.get('billing_postcode')?.value || !this.userForm.get('billing_street')?.value || !this.userForm.get('billing_number')?.value) {
          return this.toastService.error('Preencha os campos antes de prosseguir.');
        }
        break;
      case 4:
        if (!this.userForm.get('billing_district')?.value || !this.userForm.get('billing_city')?.value || !this.userForm.get('billing_state')?.value) {
          return this.toastService.error('Preencha os campos antes de prosseguir.');
        }
        break;
    }

    this.step++
  }

  updateBillingInfos() {
    const user = this.userForm.getRawValue() as User;
    user.billing_update = true;

    this.userService.save(user).subscribe(user => {
      this.user = user;
      this.toastService.success('Dados atualizados com sucesso!');
      this.modalRef.close();

      if (this.authService.getSimulatedUser()) {
        this.authService.setSimulatedUser(user);
      } else {
        this.authService.setUser(user);
      }
    })
  }

  ngAfterViewInit(): void {
      
  }

  formatCpfCnpj(e) {
    const cleanedValue = e.target.value.replace(/\D/g, '');

    if (e.target.value.length < this.oldCNPJCPF.length) {
      this.oldCNPJCPF = e.target.value
      return;
    }
    if (cleanedValue.length <= 11) {
      // Aplica máscara para CPF parcial
      const newValue = cleanedValue.replace(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, '$1.$2.$3-$4');
      this.oldCNPJCPF = newValue.toString();
      return e.target.value = newValue
    } else {
      // Aplica máscara para CNPJ parcial
      const newValue = cleanedValue.replace(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/, '$1.$2.$3/$4-$5');
      this.oldCNPJCPF = newValue.toString();
      return e.target.value = newValue
    }
  }

  cepChanged(e): void {
    const postcode = e.target.value ?? '';

    if ([8, 9].includes(postcode.length)) {
      this.errorCEP = false;
      const cepNumbers = postcode.replace(/\D/, '');
      fetch(`https://viacep.com.br/ws/${cepNumbers}/json`)
        .then(res => res.json().then(data => {
          this.userForm.patchValue({
            billing_street: data.logradouro,
            billing_city: data.localidade,
            billing_district: data.bairro,
            billin_complement: data.complemento,
            billing_state: data.uf
          });

          const signal = e.target.value.includes('-') ? '' : '-';

          e.target.value = e.target.value.slice(0, 5) + signal + e.target.value.slice(5, e.target.value.length);

          setTimeout(() => {
            document.getElementById('billing_number')?.focus();
          }, 200);
        })).catch(e => {
          this.errorCEP = true;
          return
        })
    }
  }

}
